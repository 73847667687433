import { TOKENITEM } from '../../core/auth/models/auth-token-info.model';
import { IMPERSONATING_COOKIE, REDIRECT_COOKIE } from '../../core/auth/auth.service';
import { LANG_COOKIE } from '../../core/locale/locale.service';

/**
 * Cookie for has_agreed_end_user
 */
export const HAS_AGREED_END_USER = 'dsHasAgreedEndUser';

/**
 * Storage name used to store klaro cookie
 */
export const ANONYMOUS_STORAGE_NAME_KLARO = 'klaro-anonymous';

/**
 * Klaro configuration
 * For more information see https://kiprotect.com/docs/klaro/annotated-config
 */
export const klaroConfiguration: any = {
  storageName: ANONYMOUS_STORAGE_NAME_KLARO,

  privacyPolicy: '/info/privacy',

  /*
  Setting 'hideLearnMore' to 'true' will hide the "learn more / customize" link in
  the consent notice. We strongly advise against using this under most
  circumstances, as it keeps the user from customizing his/her consent choices.
  */
  hideLearnMore: false,

  /*
  Setting 'acceptAll' to 'true' will show an "accept all" button in the notice and
  modal, which will enable all third-party services if the user clicks on it. If set
  to 'false', there will be an "accept" button that will only enable the services that
  are enabled in the consent modal.
  */
  acceptAll: true,

  /*
  You can also set a custom expiration time for the Klaro cookie. By default, it
  will expire after 30 days. Only relevant if 'storageMethod' is set to 'cookie'.
  */
  cookieExpiresAfterDays: 365,

  htmlTexts: true,

  /*
  You can overwrite existing translations and add translations for your app
  descriptions and purposes. See `src/translations/` for a full list of
  translations that can be overwritten:
  https://github.com/KIProtect/klaro/tree/master/src/translations
  */
  translations: {
    en: {
      acceptAll: 'cookies.consent.accept-all',
      acceptSelected: 'cookies.consent.accept-selected',
      app: {
        optOut: {
          description: 'cookies.consent.app.opt-out.description',
          title: 'cookies.consent.app.opt-out.title'
        },
        purpose: 'cookies.consent.app.purpose',
        purposes: 'cookies.consent.app.purposes',
        required: {
          description: 'cookies.consent.app.required.description',
          title: 'cookies.consent.app.required.title'
        }
      },
      close: 'cookies.consent.close',
      decline: 'cookies.consent.decline',
      changeDescription: 'cookies.consent.update',
      consentNotice: {
        description: 'cookies.consent.content-notice.description',
        learnMore: 'cookies.consent.content-notice.learnMore'
      },
      consentModal: {
        description: 'cookies.consent.content-modal.description',
        privacyPolicy: {
          name: 'cookies.consent.content-modal.privacy-policy.name',
          text: 'cookies.consent.content-modal.privacy-policy.text'
        },
        title: 'cookies.consent.content-modal.title'
      },
      purposes: {}
    }
  },
  services: [
    {
      name: 'authentication',
      purposes: ['functional'],
      required: true,
      cookies: [
        TOKENITEM,
        IMPERSONATING_COOKIE,
        REDIRECT_COOKIE
      ]
    },
    {
      name: 'preferences',
      purposes: ['functional'],
      required: true,
      cookies: [
        LANG_COOKIE
      ]
    },
    {
      name: 'acknowledgement',
      purposes: ['functional'],
      required: true,
      cookies: [
        [/^klaro-.+$/],
        HAS_AGREED_END_USER
      ]
    },
  ],
};
